// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-car-make-js": () => import("./../src/templates/car_make.js" /* webpackChunkName: "component---src-templates-car-make-js" */),
  "component---src-templates-car-model-js": () => import("./../src/templates/car_model.js" /* webpackChunkName: "component---src-templates-car-model-js" */),
  "component---src-templates-car-trim-js": () => import("./../src/templates/car_trim.js" /* webpackChunkName: "component---src-templates-car-trim-js" */),
  "component---src-templates-car-year-js": () => import("./../src/templates/car_year.js" /* webpackChunkName: "component---src-templates-car-year-js" */)
}

